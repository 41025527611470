<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Receipt
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs2>
                <v-text-field
                  :disabled="!sodEnable"
                  placeholder="ST-76001"
                  v-model="enrollCode"
                  class="pa-0"
                  label="Enroll Code"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs5>
                <!-- @keyup="searchEnable=true" -->
                <!-- @change="searchEnable=false" -->
                <v-autocomplete
                  :disabled="!sodEnable"
                  :search-input.sync="search"
                  :items="students"
                  hide-no-data
                  clearable
                  :loading="studentLoading"
                  class="pa-0"
                  label="Search Students"
                  v-model="form.name"
                  :hint="!students.length ? 'Type atleast 3 characters' : ''"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3>
                <transition
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <search-button
                    style=""
                    permission="receipt-read"
                    @action="searchStudent"
                  >
                    Search
                  </search-button>
                </transition>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-container>
            <strong v-if="!sodEnable" style="color:#ff5252;font-size: 16px;">
              <v-icon color="#ff5252">warning</v-icon>
              {{ sod_date }}</strong
            >
            <strong v-if="sodEnable">
              <v-icon small>date_range</v-icon>
              Transaction Date : {{ sod_date }}
            </strong>
            <p v-if="advance > 0" class="success--text">
              Advance Amount : {{ advance }}
            </p>
          </v-container>
          <v-data-table
            :headers="headers"
            v-model="selected"
            :single-expand="singleExpand"
            :items="record"
            :server-items-length="form.items.meta.total"
            show-select
            :expanded.sync="expanded"
            show-expand
            :loading="form.loading"
          >
            <template
              v-slot:item.data-table-expand="{ expand, isExpanded, item }"
            >
              <span v-if="selected.includes(item)">
                <v-icon
                  style="cursor: pointer"
                  color="error"
                  v-if="isExpanded"
                  @click="expand(!isExpanded)"
                >
                  indeterminate_check_box
                </v-icon>
                <v-icon
                  style="cursor: pointer"
                  color="primary"
                  v-else
                  @click="expand(!isExpanded)"
                >
                  add_circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon style="cursor: no-drop">
                  add_circle
                </v-icon>
              </span>
            </template>
            <template v-slot:item.bill_amount="{ item }">
              {{ item.bill_amount.currency() }}
            </template>
            <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox
                primary
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
            `
            <template v-slot:item.discount="{ item }">
              <input
                autocomplete="off"
                class="boxField"
                @keyup="calculation()"
                v-model="record[item.sn].instant_discount"
                :disabled="item.enable || bulk"
              />
            </template>
            `
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card style="width: 100%;" v-if="selected">
                  <table width="100%" class="inner-table">
                    <thead>
                      <tr>
                        <th class="text-xs-left"><strong>Fee Head</strong></th>
                        <th class="text-xs-left"><strong>Amount</strong></th>
                        <th class="text-xs-left">
                          <strong>Payment Amount</strong>
                        </th>
                        <th class="text-xs-left"><strong>Discount</strong></th>
                      </tr>
                      <tr
                        v-for="(x, i) in item.details"
                        :key="i"
                        style="height: 0px"
                      >
                        <td class="text-xs-left">{{ x.fee_head }}</td>
                        <td class="text-xs-left">{{ x.due_amount }}</td>
                        <td class="text-xs-left">
                          <input
                            autocomplete="off"
                            class="boxField"
                            @keyup="calculation()"
                            v-model="x.amount"
                          />
                        </td>
                        <td class="text-xs-left">
                          <input
                            autocomplete="off"
                            class="boxField"
                            @keyup="calculation()"
                            v-model="x.instant_discount"
                            :disabled="bulk"
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </v-card>
              </td>
            </template>
            <template slot="footer" v-if="record.length">
              <tr>
                <th :colspan="5" class="text-xs-right">Total Amount</th>
                <th class="text-xs-center">{{ totalSum.currency() }}</th>
                <th></th>
              </tr>
              <tr>
                <th :colspan="5" class="text-xs-right">
                  Total Selected Amount
                </th>
                <th class="text-xs-center">{{ selectedSum.currency() }}</th>
                <th></th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>Bulk</th>
                <th class="text-xs-right">
                  <v-checkbox primary hide-details v-model="bulk"></v-checkbox>
                </th>
                <th class="text-xs-right">
                  <span v-if="!bulk" style="color: #8b8686">Discount</span>
                  <span v-else>Discount</span>
                </th>
                <th class="text-xs-center">
                  <input
                    autocomplete="off"
                    class="boxField"
                    label="Bulk"
                    v-model="discount"
                    @keyup="discountCal"
                    :disabled="!bulk"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="6" class="text-xs-right">Amount to Pay</th>
                <th class="text-xs-center">
                  <input
                    autocomplete="off"
                    class="boxField"
                    v-model="amount_to_pay"
                    @change="amountToPayChange"
                    :disabled="selectedSum < totalSum"
                  />
                </th>
              </tr>
              <tr>
                <th colspan="6" class="text-xs-right">Total</th>
                <th class="text-xs-center">
                  {{
                    (
                      parseFloat(amount_to_pay) + parseFloat(discount)
                    ).currency()
                  }}
                </th>
              </tr>
              <tr
                v-if="
                  parseFloat(amount_to_pay) + parseFloat(discount) >
                    parseFloat(totalSum)
                "
                style="color: red"
              >
                <th colspan="6" class="text-xs-right">Advance</th>
                <th class="text-xs-center">
                  {{
                    (
                      parseFloat(amount_to_pay) -
                      parseFloat(totalSum) +
                      parseFloat(discount)
                    ).currency()
                  }}
                </th>
              </tr>
            </template>
          </v-data-table>
          <v-card-title v-if="record.length">
            <v-flex xs12 sm12 class="text-xs-right">
              <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <v-btn
                  style="width: 100%"
                  v-if="$auth.can('receipt-create')"
                  outlined
                  color="primary"
                  @click="confirmDialog = true"
                  ma-0
                  :disabled="amount_to_pay < 0"
                  >Pay
                </v-btn>
              </transition>
            </v-flex>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="confirmDialog" persistent max-width="650px">
      <v-card>
        <v-card-title class="title pa-3 warning white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          <v-chip>{{ parseFloat(amount_to_pay) }}</v-chip>

          <v-chip color="green" text-color="white"
            >{{ convertNumberToWords(parseFloat(amount_to_pay), true) }}
          </v-chip>
          <br />
          Are you sure you want to pay this amount?
        </v-card-text>
        <v-card-text>
          <v-layout row wrap pa-3>
            <v-flex xs4 sm4 class="bankInfo">
              <v-select
                :items="paymentMethod"
                label="Payment Method"
                v-model="payment_method"
                outlined
                dense
              />
            </v-flex>
            <v-flex xs4 sm4 v-if="payment_method === 'cheque'" class="bankInfo">
              <v-text-field
                v-model="cheque_no"
                label="Cheque No"
                outlined
                dense
              />
            </v-flex>
            <v-flex
              xs4
              sm4
              v-if="payment_method === 'cheque'"
              class="bankInfo"
              style="margin-right: -15px"
            >
              <v-text-field
                v-model="bank_name"
                label="Bank Name"
                outlined
                dense
              />
            </v-flex>
            <v-flex xs12 sm12>
              <v-textarea
                rows="2"
                outlined
                dense
                v-model="remarks"
                label="Remarks"
                hint="Comment any other information"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            small
            @click="
              (confirmDialog = false), (remarks = ''), (payment_method = 'cash')
            "
            >Cancel
          </v-btn>
          <v-btn
            color="warning"
            outlined
            small
            @click="submit"
            :disabled="!payment_method"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="460px">
      <v-card>
        <v-card-title class="title pa-3 error white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          Sod date is not set for today's Transaction . Are you sure want to
          continue with this
          <span style="color: red"> {{ sod_date }} </span>date?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined small @click="alertDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined small @click="alertDialog = false"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

import { convertNumberToWords, formatNumber } from "@/library/helpers";
// import {formatNumber} from '@/library/helpers'
const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
import Form from "@/library/Form";

let typingTimer;
let doneTypingInterval = 300;

export default {
  data: () => ({
    form: new Form(
      {
        bill_month: "",
        grade_id: "",
        enroll: "",
        name: "",
      },
      "/api/invoice"
    ),
    selected: [],
    advance: 0,
    expanded: [],
    singleSelect: false,
    singleExpand: false,
    discount: 0,
    expand: false,
    bulk: true,
    record: [],
    amount_to_pay: 0,
    selectedSum: 0,
    search: null,
    headers: [
      { text: "S. No", align: "left", value: "id", sortable: false },
      {
        text: "Invoice No",
        align: "left",
        value: "invoice_no",
        sortable: false,
      },
      {
        text: "Bill Months",
        align: "left",
        value: "monthname",
        sortable: false,
      },
      {
        text: "Due Amount",
        align: "left",
        value: "bill_amount",
        sortable: false,
      },
      { text: "Discount", align: "left", value: "discount", sortable: false },
    ],
    paymentMethod: [
      { text: "Cash", value: "cash" },
      { text: "Cheque", value: "cheque" },
    ],
    grades: [],
    today: nd.format("YYYY-MM-DD"),
    studentData: false,
    studentLoading: false,
    payment_method: "cash",
    enrollCode: "",
    enroll_id: "",
    months: [],
    month: "",
    studentSection: "",
    enrollId: "",
    generate: false,
    print: false,
    billingSetting: {},
    confirmDialog: false,
    items: [],
    name: "",
    input: false,
    roll: null,
    grade: null,
    section: null,
    fee_heads: [],
    datavalue: false,
    bankInfo: false,
    remarks: "",
    bank_name: "",
    cheque_no: "",
    sod_date: "",
    sodEnable: false,
    paymentDetail: [{ ids: "", amount: 0, discount: 0 }],
    adv: "",
    totalSum: 0,
    students: [],
    alertDialog: false,
    searchEnable: true,
    remainingAdvance: 0,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.currentSod();
    // this.searchStudent();
  },

  watch: {
    batch: function(value) {},
    enrollCode: function(value) {
      this.studentData = false;
      this.form.name = "";
      if (value && value.length > 7) {
        this.searchStudent();
      }
    },
    search(val) {
      if (!val) {
        this.students = [];
        this.studentLoading = false;
        return;
      }

      if (this.isLoading) return;

      if (val.length < 2) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
        $this.searchStudentOnSelect(val);
      }, doneTypingInterval);
    },
    selected: function(v) {
      this.selected.map((res) => {
        res.flag = true;
      });

      this.calculation();
    },
    "form.name": function(value) {
      this.students.filter((res) => {
        if (res.value == value) {
          this.enrollCode = res.enrollCode;
          this.enrollId = res.enrollId;
          this.studentSection = res.sectionId;
        }
      });
      this.record = [];
    },
    bulk: function(v) {
      // console.log(this.selected);
      // if (v) {
      //     if(this.selected){
      //         this.selected.map(res => {
      //             res.instant_discount = 0
      //             res.detais.map(detail=> {
      //                 detail.instant_discount = 0
      //             })
      //         })
      //     }
      //
      // }
      this.calculation();
    },
  },

  methods: {
    convertNumberToWords,
    searchStudentOnSelect(val) {
      this.$rest
        .get("api/search-student-grade-section?search=" + val)
        .then(({ data }) => {
          this.students = data.data.map((item) => {
            // this.enroll_id = item.enroll_id;
            return {
              value: item.enroll_code,
              text:
                item.name +
                " (" +
                item.grade +
                "-" +
                item.section +
                ") - " +
                item.roll,
              enrollCode: item.enroll_code,
              enrollId: item.enroll_id,
              sectionId: item.section_id,
            };
          });
        })
        .catch((err) => {})
        .finally(() => (this.studentLoading = false));
    },

    searchStudent() {
      this.$rest
        .get(
          "/api/invoice/student?enrollId=" +
            this.enrollId +
            "&sectionId=" +
            this.studentSection
        )
        .then(({ data }) => {
          // this.$rest.get('/api/invoice/student?enrollId=174&sectionId=35').then(({data}) => {
          this.selected = [];
          this.totalSum = 0;
          this.amount_to_pay = 0;
          this.record = data.data;
          console.log(data.advance);
          this.advance = data.advance;
          this.record.map((res, index) => {
            res.bill_amount = 0;
            res.instant_discount = 0;
            res.details.map((detail) => {
              detail.amount = detail.due_amount;
              res.bill_amount += detail.due_amount;
            });
            res.enable = false;
            res.sn = index;
            this.totalSum += res.bill_amount;
            this.amount_to_pay += res.bill_amount;
            this.selectedSum += res.bill_amount;
            this.selected.push(res);
          });
          // console.log(this.record)
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: "No record found.",
            status: "error",
          });
        });
    },
    // indvidialDiscountChange() {
    //     this.calculation()
    // },
    save() {
      if (
        this.payment_method === "cheque" &&
        (!this.cheque_no || !this.bank_name)
      ) {
        this.$events.fire("notification", {
          message: "Please fill the  Bank/Cheuqe information correctly.",
          status: "error",
        });
      } else {
        this.confirmDialog = true;
      }
    },
    submit() {
      if (
        this.payment_method === "cheque" &&
        (!this.cheque_no || !this.bank_name)
      ) {
        this.$events.fire("notification", {
          message: "Please fill the  Bank/Cheuqe information correctly.",
          status: "error",
        });
      } else {
        let FormData = {
          bulk: this.bulk ? "true" : "false",
          discount_amount: this.discount,
          enroll_id: this.enroll_id,
          total_amount: this.amount_to_pay,
          invoices: this.selected,
          via: this.payment_method,
          advance:
            this.amount_to_pay > this.totalSum
              ? this.amount_to_pay - this.totalSum
              : 0,
          cheque_no: this.cheque_no,
          bank_name: this.bank_name,
          sod_date: this.sod_date,
          remarks: this.remarks,
        };

        console.log(FormData);

        return;
        this.$rest
          .post("/api/fee-payment-v2", {
            bulk: this.bulk ? "true" : "false",
            discount_amount: this.discount,
            enroll_id: this.enroll_id,
            total_amount: this.amount_to_pay,
            invoices: this.selected,
            via: this.payment_method,
            advance:
              this.amount_to_pay > this.totalSum
                ? this.amount_to_pay - this.totalSum
                : 0,
            cheque_no: this.cheque_no,
            bank_name: this.bank_name,
            sod_date: this.sod_date,
            remarks: this.remarks,
          })
          .then(({ data }) => {
            this.$events.fire("notification", {
              message: data.message,
              status: "success",
            });
            this.enrollCode = "";
            this.form.name = "";
            this.record = [];
            this.search = "";
            this.remarks = "";
            this.payment_method = "cash";
            this.confirmDialog = false;
            this.expand = false;
            this.bulk = true;
            this.students = [];
            window.open(data.data.url);
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: e.message,
              status: "error",
            });
            this.confirmDialog = false;
          });
      }
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.record.slice();
    },
    currentSod() {
      this.$rest.get("/api/sod-current").then(({ data }) => {
        if (data.sod) {
          this.sodEnable = true;
          this.sod_date = data.sod;
          if (this.today != this.sod_date) this.alertDialog = true;
        } else {
          this.sodEnable = false;
          this.sod_date =
            "Start of day has not been set. Please contact your admin.";
        }
      });
    },
    calculation() {
      // console.log('calc');
      this.amount_to_pay = 0;
      this.discount = 0;
      this.selectedSum = 0;
      this.amount_to_pay = 0;
      this.selectedSum = 0;
      this.selected.map((res) => {
        let sumInstantDiscount = 0;
        res.details.map((detail) => {
          this.amount_to_pay += parseFloat(detail.amount);
          // res.instant_discount += parseFloat(detail.instant_discount);
          detail.amount =
            detail.amount >= detail.due_amount
              ? detail.due_amount
              : detail.amount;
          detail.instant_discount = !this.bulk
            ? detail.instant_discount > detail.due_amount
              ? detail.due_amount
              : detail.instant_discount
            : 0;
          sumInstantDiscount += parseFloat(detail.instant_discount);
          this.selectedSum += parseFloat(detail.amount);

          // console.log('gdsginbg')
        });
        res.instant_discount = !this.bulk
          ? !["", NaN, undefined].includes(sumInstantDiscount)
            ? sumInstantDiscount
            : 0
          : 0;

        this.discount += res.instant_discount;
      });

      this.amount_to_pay =
        parseFloat(this.selectedSum) - parseFloat(this.discount);

      if (["", NaN, undefined].includes(this.amount_to_pay)) {
        this.amount_to_pay = 0;
      }
    },
    discountCal() {
      if (this.discount > this.selectedSum) this.discount = this.selectedSum;
      this.amount_to_pay =
        parseFloat(this.selectedSum) - parseFloat(this.discount);
      if (["", NaN, undefined].includes(this.amount_to_pay)) {
        this.amount_to_pay = 0;
      }
    },
    amountToPayChange() {
      if (this.amount_to_pay < this.selectedSum) {
        this.amount_to_pay = this.selectedSum;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table.v-datatable.v-table.theme--light {
  width: 100%;
}

.amountPayable {
  position: absolute;
  margin-top: 23px;
  font-size: 10px;
  font-weight: 600;
  margin-left: -80px;
}

.boxField {
  border: 1px solid #ccc;
  font-weight: bold;
  border-radius: 3px;
  width: 80px;
  height: 25px !important;
  text-align: center !important;
}

.boxField:disabled {
  background: #bfbaba3d;
  cursor: no-drop;
}

.bankInfo {
  margin-left: 5px;
  width: 100%;
}

.highlight {
  td {
    font-weight: bold !important;
  }
}

tr.highlight {
  cursor: pointer;
}
</style>
